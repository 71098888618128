import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import findImage from '../../utils/findImage'

import Layout from '../../components/Layout'
import Preview from '../../components/Preview'
import WorkTitleBlock from '../../components/WorkTitleBlock';

export const frontmatter = {
  title: 'Kupuy Site',
  type: 'web',
  client: 'Minfin',
  path: '/works/web/kupuy-site/',
  date: '2013-11-25',
  image: 'kupuy-site-demo',
  favorite: true,
  work_type: 'Site'
}

export default ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: { relativePath: { regex: "^/kupuy/i/" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  const images = query.images.edges
  return (
    <Layout location={location}>
      <Helmet title={frontmatter.title} />

      <h1 className="text-center">{frontmatter.title}</h1>
      <p className="aligncenter text-center">
        Design the website explaining the benefits of using the credit.
      </p>

      <figure className="text-center">
        <Preview
          fluid={findImage(images, 'kupuy-home')}
          alt="Home page of the Kupuy site"
          url="Buy on credit"
          width="900"
          height="1433"
        />
      </figure>

      <WorkTitleBlock {...frontmatter} />
    </Layout>
  )
}
